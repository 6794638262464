import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from 'gatsby';
import { NavbarBasic, NavbarBlue, NavbarWithoutHamburger } from '../../examples/components/Navbar';
import TrimbleLogo from '../../assets/img/trimble-logo.svg';
import TrimbleIcon from '../../assets/img/trimble-icon.svg';
import TrimbleLogoRev from '../../assets/img/trimble-logo-rev.svg';
import TrimbleIconRev from '../../assets/img/trimble-icon-rev.svg';
export const query = graphql`
  query NavbarQuery {
    Navbar: componentMetadata(displayName: { eq: "Navbar" }) {
      ...ComponentApi_metadata
    }
    NavbarBrand: componentMetadata(displayName: { eq: "NavbarBrand" }) {
      ...ComponentApi_metadata
    }
    NavbarToggle: componentMetadata(displayName: { eq: "NavbarToggle" }) {
      ...ComponentApi_metadata
    }
    NavbarCollapse: componentMetadata(displayName: { eq: "NavbarCollapse" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 className='h1 font-weight-bold' id='Overview'>
  Overview
    </h2>
    <CodeBlock style={{
      padding: '0px'
    }} hideCode={true} scope={{
      TrimbleLogo,
      TrimbleIcon
    }} code={NavbarBasic} mdxType="CodeBlock">
  The navbar or the app header provides context through globally accessible menu options and
  positions a consistent component to connect various Trimble applications and contains the main
  navigation for your application.
    </CodeBlock>
    <CodeBlock title='Blue Variant' style={{
      padding: '0px'
    }} scope={{
      TrimbleLogoRev,
      TrimbleIconRev
    }} code={NavbarBlue} hideCode={true} mdxType="CodeBlock">
  An alternative blue variant is available. Add the class `navbar-blue` to the navbar.
    </CodeBlock>
    <CodeBlock title='Navbar without Menu button' style={{
      padding: '0px'
    }} scope={{
      TrimbleLogoRev,
      TrimbleIconRev
    }} code={NavbarWithoutHamburger} hideCode={true} mdxType="CodeBlock">
  You can omit the navbar menu button if it isn’t needed.
    </CodeBlock>
    <LinkedHeading h='2' className='h1' id='navbar-api' mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Navbar} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.NavbarBrand} exportedBy={props.data.Navbar} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.NavbarToggle} exportedBy={props.data.Navbar} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.NavbarCollapse} exportedBy={props.data.Navbar} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      